import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "resend-email",
    path: `${AUTH_PREFIX_PATH}/success-send-email`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/resend-email/forgot")
    ),
  },
  {
    key: "reset-password",
    path: `${AUTH_PREFIX_PATH}/reset-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/reset-password")
    ),
  },
  {
    key: "change-password",
    path: `${AUTH_PREFIX_PATH}/change-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/change-password")
    ),
  },
  {
    key: "error",
    path: `*`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
    meta: {
      blankLayout: true,
    },
  },
];

export const protectedRoutes = [
  {
    key: "dashboard",
    path: `${APP_PREFIX_PATH}`,
    component: React.lazy(() => import("views/app-views/onboarding")),
  },

  // Tarif Umum
  {
    key: "tarif-umum",
    path: `${APP_PREFIX_PATH}/tarif-umum`,
    component: React.lazy(() =>
      import("views/app-views/commercial/tarif-umum")
    ),
  },
  {
    key: "tarif-umum-form",
    path: `${APP_PREFIX_PATH}/tarif-umum/form`,
    component: React.lazy(() =>
      import("views/app-views/commercial/tarif-umum/form")
    ),
  },

  // Data Pembayar
  {
    key: "pembayar-data",
    path: `${APP_PREFIX_PATH}/commercial/pembayar`,
    component: React.lazy(() =>
      import("views/app-views/commercial/pembayar/data")
    ),
  },
  {
    key: "pembayar-form",
    path: `${APP_PREFIX_PATH}/commercial/pembayar/form`,
    component: React.lazy(() =>
      import("views/app-views/commercial/pembayar/form")
    ),
  },
  {
    key: "pembayar-detail",
    path: `${APP_PREFIX_PATH}/commercial/pembayar/detail`,
    component: React.lazy(() =>
      import("views/app-views/commercial/pembayar/detail")
    ),
  },
  {
    key: "pembayar-customer-service-form",
    path: `${APP_PREFIX_PATH}/commercial/pembayar/customer-service/form`,
    component: React.lazy(() =>
      import("views/app-views/commercial/pembayar/detail/customer-service/form")
    ),
  },
  {
    key: "pembayar-customer-ar-form",
    path: `${APP_PREFIX_PATH}/commercial/pembayar/customer-ar/form`,
    component: React.lazy(() =>
      import("views/app-views/commercial/pembayar/detail/customer-ar/form")
    ),
  },
  {
    key: "pembayar-customer-bank-form",
    path: `${APP_PREFIX_PATH}/commercial/pembayar/customer-bank/form`,
    component: React.lazy(() =>
      import("views/app-views/commercial/pembayar/detail/customer-bank/form")
    ),
  },

  // Tarif Pembayar
  {
    key: "tarif-pembayar-form",
    path: `${APP_PREFIX_PATH}/commercial/pembayar/pricelist/form`,
    component: React.lazy(() =>
      import("views/app-views/commercial/pembayar/detail/tarif/form")
    ),
  },
  {
    key: "tarif-pembayar-detail",
    path: `${APP_PREFIX_PATH}/commercial/pembayar/pricelist/detail`,
    component: React.lazy(() =>
      import("views/app-views/commercial/pembayar/detail/tarif/detail")
    ),
  },

  // Pengirim Penerima
  {
    key: "pengirim-penerima-data",
    path: `${APP_PREFIX_PATH}/commercial/pengirim-penerima`,
    component: React.lazy(() =>
      import("views/app-views/commercial/pengirim-penerima/data")
    ),
  },
  {
    key: "pengirim-penerima-detail",
    path: `${APP_PREFIX_PATH}/commercial/pengirim-penerima/detail`,
    component: React.lazy(() =>
      import("views/app-views/commercial/pengirim-penerima/detail")
    ),
  },
  {
    key: "pengirim-penerima-form",
    path: `${APP_PREFIX_PATH}/commercial/pengirim-penerima/form`,
    component: React.lazy(() =>
      import("views/app-views/commercial/pengirim-penerima/form")
    ),
  },
  {
    key: "component-address-form",
    path: `${APP_PREFIX_PATH}/commercial/pengirim-penerima/alamat/form`,
    component: React.lazy(() =>
      import("views/app-views/commercial/pengirim-penerima/address/form")
    ),
  },

  // Booking Order
  {
    key: "booking-data",
    path: `${APP_PREFIX_PATH}/commercial/booking-order`,
    component: React.lazy(() =>
      import("views/app-views/commercial/booking/data")
    ),
  },

  // Pickup Order
  {
    key: "pickup-data",
    path: `${APP_PREFIX_PATH}/commercial/pickup-order`,
    component: React.lazy(() =>
      import("views/app-views/commercial/pickup/data")
    ),
  },
  {
    key: "pickup-detail",
    path: `${APP_PREFIX_PATH}/commercial/pickup-order/detail`,
    component: React.lazy(() =>
      import("views/app-views/commercial/pickup/detail")
    ),
  },
  {
    key: "pickup-form",
    path: `${APP_PREFIX_PATH}/commercial/pickup-order/form`,
    component: React.lazy(() =>
      import("views/app-views/commercial/pickup/form")
    ),
  },
  {
    key: "pickup-print-pio",
    path: `${APP_PREFIX_PATH}/commercial/pickup-order/print/pio`,
    component: React.lazy(() =>
      import("views/app-views/commercial/pickup/print/pio")
    ),
    meta: {
      blankLayout: true,
    },
  },
  {
    key: "pickup-print-preview",
    path: `${APP_PREFIX_PATH}/commercial/pickup-order/print/preview`,
    component: React.lazy(() =>
      import("views/app-views/commercial/pickup/print/preview")
    ),
    meta: {
      blankLayout: true,
    },
  },
  {
    key: "pickup-print-preview",
    path: `${APP_PREFIX_PATH}/commercial/pickup-order/print/sjp`,
    component: React.lazy(() =>
      import("views/app-views/commercial/pickup/print/sjp")
    ),
    meta: {
      blankLayout: true,
    },
  },

  // Komisi CS/SM
  {
    key: "staff-commission",
    path: `${APP_PREFIX_PATH}/commercial/commission`,
    component: React.lazy(() =>
      import("views/app-views/commercial/commission")
    ),
  },

  // Invoice Jual
  {
    key: "sales-invoice",
    path: `${APP_PREFIX_PATH}/invoices`,
    component: React.lazy(() => import("views/app-views/invoice/data")),
  },
  {
    key: "sales-invoice-detail",
    path: `${APP_PREFIX_PATH}/invoices/detail`,
    component: React.lazy(() => import("views/app-views/invoice/detail")),
  },
  {
    key: "sales-invoice-form",
    path: `${APP_PREFIX_PATH}/invoices/from`,
    component: React.lazy(() => import("views/app-views/invoice/form")),
  },
  {
    key: "invoice-print",
    path: `${APP_PREFIX_PATH}/invoices/print/invoice`,
    component: React.lazy(() =>
      import("views/app-views/invoice/print/invoice")
    ),
    meta: {
      blankLayout: true,
    },
  },
  {
    key: "invoice-preview",
    path: `${APP_PREFIX_PATH}/invoices/print/preview`,
    component: React.lazy(() =>
      import("views/app-views/invoice/print/preview")
    ),
    meta: {
      blankLayout: true,
    },
  },

  // Pengantar Invoice
  {
    key: "pengantar-invoice-data",
    path: `${APP_PREFIX_PATH}/account-receivable/pengantar-invoice`,
    component: React.lazy(() =>
      import(
        "views/app-views/finance-accounting/account-receivable/pengantar-invoice/data"
      )
    ),
  },
  {
    key: "pengantar-invoice-detail",
    path: `${APP_PREFIX_PATH}/account-receivable/pengantar-invoice/detail`,
    component: React.lazy(() =>
      import(
        "views/app-views/finance-accounting/account-receivable/pengantar-invoice/detail"
      )
    ),
  },
  {
    key: "pengantar-invoice-form",
    path: `${APP_PREFIX_PATH}/account-receivable/pengantar-invoice/form`,
    component: React.lazy(() =>
      import(
        "views/app-views/finance-accounting/account-receivable/pengantar-invoice/form"
      )
    ),
  },
  {
    key: "pengantar-invoice-print",
    path: `${APP_PREFIX_PATH}/account-receivable/pengantar-invoice/print`,
    component: React.lazy(() =>
      import(
        "views/app-views/finance-accounting/account-receivable/pengantar-invoice/print"
      )
    ),
    meta: {
      blankLayout: true,
    },
  },

  //Info AR
  {
    key: "info-ar-data",
    path: `${APP_PREFIX_PATH}/account-receivable/info-ar`,
    component: React.lazy(() =>
      import(
        "views/app-views/finance-accounting/account-receivable/info-ar/data"
      )
    ),
  },

  //Pelunasan
  {
    key: "pelunasan-data",
    path: `${APP_PREFIX_PATH}/account-receivable/pelunasan`,
    component: React.lazy(() =>
      import(
        "views/app-views/finance-accounting/account-receivable/pelunasan/data"
      )
    ),
  },
  {
    key: "pelunasan-detail",
    path: `${APP_PREFIX_PATH}/account-receivable/pelunasan/detail`,
    component: React.lazy(() =>
      import(
        "views/app-views/finance-accounting/account-receivable/pelunasan/detail"
      )
    ),
  },
  {
    key: "pelunasan-form",
    path: `${APP_PREFIX_PATH}/account-receivable/pelunasan/form`,
    component: React.lazy(() =>
      import(
        "views/app-views/finance-accounting/account-receivable/pelunasan/form"
      )
    ),
  },
  {
    key: "pelunasan-detail-form",
    path: `${APP_PREFIX_PATH}/account-receivable/pelunasan/tambah-pelunasan/form`,
    component: React.lazy(() =>
      import(
        "views/app-views/finance-accounting/account-receivable/pelunasan/form-tambah-pelunasan"
      )
    ),
  },
  {
    key: "pelunasan-invoice-print",
    path: `${APP_PREFIX_PATH}/account-receivable/pelunasan/print`,
    component: React.lazy(() =>
      import(
        "views/app-views/finance-accounting/account-receivable/pelunasan/print"
      )
    ),
    meta: {
      blankLayout: true,
    },
  },

  //AP-Jurnal Biaya Akrual
  {
    key: "jurnal-biaya-akrual-data",
    path: `${APP_PREFIX_PATH}/account-payable/jurnal-biaya-akrual`,
    component: React.lazy(() =>
      import(
        "views/app-views/finance-accounting/account-payable/jurnal-akrual/data"
      )
    ),
  },
  {
    key: "pelunasan-detail-form",
    path: `${APP_PREFIX_PATH}/account-payable/jurnal-biaya-akrual/form`,
    component: React.lazy(() =>
      import(
        "views/app-views/finance-accounting/account-payable/jurnal-akrual/form"
      )
    ),
  },

  // Default Akun
  {
    key: "default-account",
    path: `${APP_PREFIX_PATH}/finance/default-account`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/default-account")
    ),
  },

  //AP-Tagihan
  {
    key: "tagihan-data",
    path: `${APP_PREFIX_PATH}/account-payable/tagihan`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/account-payable/tagihan/data")
    ),
  },
  {
    key: "tagihan-detail",
    path: `${APP_PREFIX_PATH}/account-payable/tagihan/detail`,
    component: React.lazy(() =>
      import(
        "views/app-views/finance-accounting/account-payable/tagihan/detail"
      )
    ),
  },
  {
    key: "tagihan-form",
    path: `${APP_PREFIX_PATH}/account-payable/tagihan/form`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/account-payable/tagihan/form")
    ),
  },

  //AP-Pembayaran Tagihan
  {
    key: "pembayaran-tagihan-data",
    path: `${APP_PREFIX_PATH}/account-payable/pembayaran-tagihan`,
    component: React.lazy(() =>
      import(
        "views/app-views/finance-accounting/account-payable/pembayaran-tagihan/data"
      )
    ),
  },
  {
    key: "pembayaran-tagihan-detail",
    path: `${APP_PREFIX_PATH}/account-payable/pembayaran-tagihan/detail`,
    component: React.lazy(() =>
      import(
        "views/app-views/finance-accounting/account-payable/pembayaran-tagihan/detail"
      )
    ),
  },
  {
    key: "pembayaran-tagihan-form",
    path: `${APP_PREFIX_PATH}/account-payable/pembayaran-tagihan/form`,
    component: React.lazy(() =>
      import(
        "views/app-views/finance-accounting/account-payable/pembayaran-tagihan/form"
      )
    ),
  },
  {
    key: "pembayaran-tagihan-print",
    path: `${APP_PREFIX_PATH}/account-payable/pembayaran-tagihan/print`,
    component: React.lazy(() =>
      import(
        "views/app-views/finance-accounting/account-payable/pembayaran-tagihan/print"
      )
    ),
    meta: {
      blankLayout: true,
    },
  },
  //AP-Pencairan Giro
  {
    key: "pencairan-giro-data",
    path: `${APP_PREFIX_PATH}/account-payable/pencairan-giro`,
    component: React.lazy(() =>
      import(
        "views/app-views/finance-accounting/account-payable/pencairan-giro/data"
      )
    ),
  },

  //AP-Info AP
  {
    key: "info-ap-data",
    path: `${APP_PREFIX_PATH}/account-payable/info-ap`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/account-payable/info-ap/data")
    ),
  },

  //AP-Transaksi Kas
  {
    key: "transaksi-kas-data",
    path: `${APP_PREFIX_PATH}/account-payable/transaksi-kas`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/transaksi/kas/data")
    ),
  },
  {
    key: "transaksi-kas-detail",
    path: `${APP_PREFIX_PATH}/account-payable/transaksi-kas/detail`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/transaksi/kas/detail")
    ),
  },
  {
    key: "transaksi-kas-form",
    path: `${APP_PREFIX_PATH}/account-payable/transaksi-kas/form`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/transaksi/kas/form")
    ),
  },
  {
    key: "transaksi-kas-print",
    path: `${APP_PREFIX_PATH}/account-payable/transaksi-kas/print`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/transaksi/kas/print")
    ),
    meta: {
      blankLayout: true,
    },
  },

  //Transaksi Bank
  {
    key: "transaksi-bank-data",
    path: `${APP_PREFIX_PATH}/account-payable/transaksi-bank`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/transaksi/bank/data")
    ),
  },
  {
    key: "transaksi-bank-detail",
    path: `${APP_PREFIX_PATH}/account-payable/transaksi-bank/detail`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/transaksi/bank/detail")
    ),
  },
  {
    key: "transaksi-bank-form",
    path: `${APP_PREFIX_PATH}/account-payable/transaksi-bank/form`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/transaksi/bank/form")
    ),
  },

  //Jurnal Umum
  {
    key: "jurnal-umum-data",
    path: `${APP_PREFIX_PATH}/account-payable/jurnal-umum`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/transaksi/jurnal-umum/data")
    ),
  },
  {
    key: "jurnal-umum-detail",
    path: `${APP_PREFIX_PATH}/account-payable/jurnal-umum/detail`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/transaksi/jurnal-umum/detail")
    ),
  },
  {
    key: "jurnal-umum-form",
    path: `${APP_PREFIX_PATH}/account-payable/jurnal-umum/form`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/transaksi/jurnal-umum/form")
    ),
  },

  //Pengajuan Dooring Tujuan
  {
    key: "pengajuan-dooring-tujuan-data",
    path: `${APP_PREFIX_PATH}/account-payable/pdt`,
    component: React.lazy(() =>
      import(
        "views/app-views/finance-accounting/transaksi/pengajuan-dooring-tujuan/data"
      )
    ),
  },
  {
    key: "pengajuan-dooring-tujuan-detail",
    path: `${APP_PREFIX_PATH}/account-payable/pdt/detail`,
    component: React.lazy(() =>
      import(
        "views/app-views/finance-accounting/transaksi/pengajuan-dooring-tujuan/detail"
      )
    ),
  },
  {
    key: "pengajuan-dooring-tujuan-form",
    path: `${APP_PREFIX_PATH}/account-payable/pdt/form`,
    component: React.lazy(() =>
      import(
        "views/app-views/finance-accounting/transaksi/pengajuan-dooring-tujuan/form"
      )
    ),
  },

  // FINANCE REPORT
  {
    key: "finance-buku-besar",
    path: `${APP_PREFIX_PATH}/finance/buku-besar`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/report/buku-besar")
    ),
  },
  {
    key: "finance-neraca-saldo",
    path: `${APP_PREFIX_PATH}/finance/neraca-saldo`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/report/neraca-saldo")
    ),
  },
  {
    key: "finance-neraca",
    path: `${APP_PREFIX_PATH}/finance/neraca`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/report/neraca")
    ),
  },
  {
    key: "finance-laba-rugi",
    path: `${APP_PREFIX_PATH}/finance/laba-rugi`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/report/laba-rugi")
    ),
  },
  {
    key: "finance-laba-rugi-konsolidasi",
    path: `${APP_PREFIX_PATH}/finance/laba-rugi-konsolidasi`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/report/laba-rugi-konsolidasi")
    ),
  },
  {
    key: "finance-tutup-buku-bulan",
    path: `${APP_PREFIX_PATH}/finance/tutup-buku/bulan`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/tutup-buku/tutup-bulan")
    ),
  },
  {
    key: "finance-tutup-buku-tahun",
    path: `${APP_PREFIX_PATH}/finance/tutup-buku/tahun`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/tutup-buku/tutup-tahun")
    ),
  },
  {
    key: "finance-tutup-buku-tahun-detail",
    path: `${APP_PREFIX_PATH}/finance/tutup-buku/tahun/detail`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/tutup-buku/tutup-tahun/detail")
    ),
  },
  {
    key: "finance-tutup-buku-tahun-form",
    path: `${APP_PREFIX_PATH}/finance/tutup-buku/tahun/form`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/tutup-buku/tutup-tahun/form")
    ),
  },

  // Faktur Pajak Input no seri
  {
    key: "input-no-seri-faktur-pajak",
    path: `${APP_PREFIX_PATH}/faktur-pajak/nomor-seri`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/faktur-pajak/input-no/data")
    ),
  },
  {
    key: "input-no-seri-faktur-pajak-detail",
    path: `${APP_PREFIX_PATH}/faktur-pajak/nomor-seri/detail`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/faktur-pajak/input-no/detail")
    ),
  },
  {
    key: "input-no-seri-faktur-pajak-form",
    path: `${APP_PREFIX_PATH}/faktur-pajak/nomor-seri/form`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/faktur-pajak/input-no/form")
    ),
  },
  // Faktur Pajak Daftar Faktur
  {
    key: "daftar-faktur-pajak",
    path: `${APP_PREFIX_PATH}/faktur-pajak/daftar-faktur-pajak`,
    component: React.lazy(() =>
      import(
        "views/app-views/finance-accounting/faktur-pajak/daftar-faktur/data"
      )
    ),
  },
  // Faktur Pajak Upload Faktur
  {
    key: "upload-faktur-pajak",
    path: `${APP_PREFIX_PATH}/faktur-pajak/upload-faktur-pajak`,
    component: React.lazy(() =>
      import("views/app-views/finance-accounting/faktur-pajak/upload-faktur")
    ),
  },

  // Job Order
  {
    key: "job-order-data",
    path: `${APP_PREFIX_PATH}/job-order`,
    component: React.lazy(() => import("views/app-views/job-order/data")),
  },
  {
    key: "job-order-detail",
    path: `${APP_PREFIX_PATH}/job-order/detail`,
    component: React.lazy(() => import("views/app-views/job-order/detail")),
  },
  {
    key: "job-order-form",
    path: `${APP_PREFIX_PATH}/job-order/form`,
    component: React.lazy(() => import("views/app-views/job-order/form")),
  },
  {
    key: "job-order-draft-billing-form",
    path: `${APP_PREFIX_PATH}/job-order/draft-billing/form`,
    component: React.lazy(() =>
      import("views/app-views/job-order/draft-billing/cost/form")
    ),
  },

  // Vendor
  {
    key: "vendor-data",
    path: `${APP_PREFIX_PATH}/procurement/vendor`,
    component: React.lazy(() =>
      import("views/app-views/procurement/vendor/data")
    ),
  },
  {
    key: "vendor-form",
    path: `${APP_PREFIX_PATH}/procurement/vendor/form`,
    component: React.lazy(() =>
      import("views/app-views/procurement/vendor/form")
    ),
  },
  {
    key: "vendor-detail",
    path: `${APP_PREFIX_PATH}/procurement/vendor/detail`,
    component: React.lazy(() =>
      import("views/app-views/procurement/vendor/detail")
    ),
  },

  // Tarif Vendor
  {
    key: "tarif-vendor-form",
    path: `${APP_PREFIX_PATH}/procurement/vendor/pricelist/form`,
    component: React.lazy(() =>
      import("views/app-views/procurement/vendor/detail/tarif/form")
    ),
  },
  {
    key: "tarif-vendor-detail",
    path: `${APP_PREFIX_PATH}/procurement/vendor/pricelist/detail`,
    component: React.lazy(() =>
      import("views/app-views/procurement/vendor/detail/tarif/detail")
    ),
  },

  // Delivery Order
  {
    key: "delivery-data",
    path: `${APP_PREFIX_PATH}/operational/delivery-order`,
    component: React.lazy(() =>
      import("views/app-views/operational/delivery/data")
    ),
  },
  {
    key: "delivery-form",
    path: `${APP_PREFIX_PATH}/operational/delivery-order/form`,
    component: React.lazy(() =>
      import("views/app-views/operational/delivery/form")
    ),
  },
  {
    key: "delivery-detail",
    path: `${APP_PREFIX_PATH}/operational/delivery-order/detail`,
    component: React.lazy(() =>
      import("views/app-views/operational/delivery/detail")
    ),
  },
  {
    key: "delivery-print",
    path: `${APP_PREFIX_PATH}/operational/delivery-order/print/bstb`,
    component: React.lazy(() =>
      import("views/app-views/operational/delivery/print/bstb")
    ),
    meta: {
      blankLayout: true,
    },
  },
  {
    key: "delivery-preview",
    path: `${APP_PREFIX_PATH}/operational/delivery-order/print/preview`,
    component: React.lazy(() =>
      import("views/app-views/operational/delivery/print/preview")
    ),
    meta: {
      blankLayout: true,
    },
  },
  // Upload BASJ
  {
    key: "upload-basj-pengirim",
    path: `${APP_PREFIX_PATH}/operational/upload-basj-pengirim`,
    component: React.lazy(() =>
      import("views/app-views/operational/upload-basj")
    ),
  },
  // Upload BTTB
  // {
  //   key: "upload-bstb-return",
  //   path: `${APP_PREFIX_PATH}/operational/upload-bstb-return`,
  //   component: React.lazy(() =>
  //     import("views/app-views/operational/upload-bstb-return")
  //   ),
  // },
  {
    key: "upload-bstb-continue",
    path: `${APP_PREFIX_PATH}/operational/upload-bstb-continue`,
    component: React.lazy(() =>
      import("views/app-views/operational/upload-bstb-continue")
    ),
  },
  {
    key: "upload-bstb-delivered",
    path: `${APP_PREFIX_PATH}/operational/upload-bstb-delivered`,
    component: React.lazy(() =>
      import("views/app-views/operational/upload-bstb-delivered")
    ),
  },
  // Upload BTTB
  {
    key: "voyage-information",
    path: `${APP_PREFIX_PATH}/operational/information`,
    component: React.lazy(() =>
      import("views/app-views/operational/information/data")
    ),
  },

  // Voyage
  {
    key: "voyage-data",
    path: `${APP_PREFIX_PATH}/operational/voyage`,
    component: React.lazy(() =>
      import("views/app-views/operational/voyage/data")
    ),
  },
  {
    key: "voyage-form",
    path: `${APP_PREFIX_PATH}/operational/voyage/form`,
    component: React.lazy(() =>
      import("views/app-views/operational/voyage/form")
    ),
  },
  {
    key: "voyage-detail",
    path: `${APP_PREFIX_PATH}/operational/voyage/detail`,
    component: React.lazy(() =>
      import("views/app-views/operational/voyage/detail")
    ),
  },
  {
    key: "voyage-print",
    path: `${APP_PREFIX_PATH}/operational/voyage/print/bstb`,
    component: React.lazy(() =>
      import("views/app-views/operational/voyage/print/bstb")
    ),
    meta: {
      blankLayout: true,
    },
  },
  // Kontainer
  {
    key: "packing-list",
    path: `${APP_PREFIX_PATH}/operational/packing-list`,
    component: React.lazy(() =>
      import("views/app-views/operational/packing-list/data")
    ),
  },
  {
    key: "packing-list-detail",
    path: `${APP_PREFIX_PATH}/operational/packing-list/detail`,
    component: React.lazy(() =>
      import("views/app-views/operational/packing-list/detail")
    ),
  },
  {
    key: "packing-list-form",
    path: `${APP_PREFIX_PATH}/operational/packing-list/form`,
    component: React.lazy(() =>
      import("views/app-views/operational/packing-list/form")
    ),
  },
  {
    key: "packing-list-print",
    path: `${APP_PREFIX_PATH}/operational/packing-list/print`,
    component: React.lazy(() =>
      import("views/app-views/operational/packing-list/print")
    ),
    meta: {
      blankLayout: true,
    },
  },
  {
    key: "packing-list-detail",
    path: `${APP_PREFIX_PATH}/operational/packing-list/detail`,
    component: React.lazy(() =>
      import("views/app-views/operational/packing-list/detail")
    ),
  },
  {
    key: "packing-list-draft-billing-form",
    path: `${APP_PREFIX_PATH}/operational/packing-list/draft-billing/form`,
    component: React.lazy(() =>
      import("views/app-views/operational/packing-list/draft-billing/cost/form")
    ),
  },
  // Pengaturan Umum
  {
    key: "general-settings",
    path: `${APP_PREFIX_PATH}/general-setting`,
    component: React.lazy(() => import("views/app-views/general-setting")),
  },

  // Cabang Operasional
  {
    key: "branch-data",
    path: `${APP_PREFIX_PATH}/master-data/cabang-operasional`,
    component: React.lazy(() =>
      import("views/app-views/master-data/operational-branch/data")
    ),
  },
  {
    key: "branch-detail",
    path: `${APP_PREFIX_PATH}/master-data/cabang-operasional/detail`,
    component: React.lazy(() =>
      import("views/app-views/master-data/operational-branch/detail")
    ),
  },
  {
    key: "branch-form",
    path: `${APP_PREFIX_PATH}/master-data/cabang-operasional/form`,
    component: React.lazy(() =>
      import("views/app-views/master-data/operational-branch/form")
    ),
  },
  // Penomoran Cabang
  {
    key: "branch-numbering",
    path: `${APP_PREFIX_PATH}/master-data/cabang-operasional/penomoran`,
    component: React.lazy(() =>
      import("views/app-views/master-data/operational-branch/numbering/data")
    ),
  },
  {
    key: "branch-numbering-form",
    path: `${APP_PREFIX_PATH}/master-data/cabang-operasional/penomoran/form`,
    component: React.lazy(() =>
      import("views/app-views/master-data/operational-branch/numbering/form")
    ),
  },

  // Data Wilayah
  {
    key: "region-data",
    path: `${APP_PREFIX_PATH}/master-data/data-wilayah`,
    component: React.lazy(() =>
      import("views/app-views/master-data/region/data")
    ),
  },
  {
    key: "region-form",
    path: `${APP_PREFIX_PATH}/master-data/data-wilayah/form`,
    component: React.lazy(() =>
      import("views/app-views/master-data/region/form")
    ),
  },

  // Kondisi Lain
  {
    key: "other-condition-data",
    path: `${APP_PREFIX_PATH}/master-data/kondisi-lain`,
    component: React.lazy(() =>
      import("views/app-views/master-data/other-condition/data")
    ),
  },
  {
    key: "other-condition-form",
    path: `${APP_PREFIX_PATH}/master-data/kondisi-lain/form`,
    component: React.lazy(() =>
      import("views/app-views/master-data/other-condition/form")
    ),
  },
  // Daftar Biaya
  {
    key: "daftar-biaya-data",
    path: `${APP_PREFIX_PATH}/master-data/daftar-biaya`,
    component: React.lazy(() =>
      import("views/app-views/master-data/master-biaya/daftar-biaya/data")
    ),
  },
  {
    key: "daftar-biaya-form",
    path: `${APP_PREFIX_PATH}/master-data/daftar-biaya/form`,
    component: React.lazy(() =>
      import("views/app-views/master-data/master-biaya/daftar-biaya/form")
    ),
  },
  // Daftar Biaya
  {
    key: "template-biaya-data",
    path: `${APP_PREFIX_PATH}/master-data/template-biaya`,
    component: React.lazy(() =>
      import("views/app-views/master-data/master-biaya/template-biaya/data")
    ),
  },
  {
    key: "template-biaya-detail",
    path: `${APP_PREFIX_PATH}/master-data/template-biaya/detail`,
    component: React.lazy(() =>
      import("views/app-views/master-data/master-biaya/template-biaya/detail")
    ),
  },
  {
    key: "template-biaya-form",
    path: `${APP_PREFIX_PATH}/master-data/template-biaya/form`,
    component: React.lazy(() =>
      import("views/app-views/master-data/master-biaya/template-biaya/form")
    ),
  },
  {
    key: "component-biaya-form",
    path: `${APP_PREFIX_PATH}/master-data/template-biaya/komponen-biaya/form`,
    component: React.lazy(() =>
      import(
        "views/app-views/master-data/master-biaya/template-biaya/component-biaya/form"
      )
    ),
  },
  // Product
  {
    key: "product-data",
    path: `${APP_PREFIX_PATH}/master-data/produk-jasa`,
    component: React.lazy(() =>
      import("views/app-views/master-data/product/data")
    ),
  },
  {
    key: "product-form",
    path: `${APP_PREFIX_PATH}/master-data/produk-jasa/form`,
    component: React.lazy(() =>
      import("views/app-views/master-data/product/form")
    ),
  },

  // Account
  {
    key: "account-data",
    path: `${APP_PREFIX_PATH}/master-data/account`,
    component: React.lazy(() =>
      import("views/app-views/master-data/account/data")
    ),
  },
  {
    key: "account-form",
    path: `${APP_PREFIX_PATH}/master-data/account/form`,
    component: React.lazy(() =>
      import("views/app-views/master-data/account/form")
    ),
  },

  // Commodity
  {
    key: "commodity-data",
    path: `${APP_PREFIX_PATH}/master-data/commodity`,
    component: React.lazy(() =>
      import("views/app-views/master-data/commodity/data")
    ),
  },
  {
    key: "commodity-form",
    path: `${APP_PREFIX_PATH}/master-data/commodity/form`,
    component: React.lazy(() =>
      import("views/app-views/master-data/commodity/form")
    ),
  },
  // Vehicle
  {
    key: "vehicle-data",
    path: `${APP_PREFIX_PATH}/master-data/vehicle`,
    component: React.lazy(() =>
      import("views/app-views/master-data/vehicle/data")
    ),
  },
  {
    key: "vehicle-form",
    path: `${APP_PREFIX_PATH}/master-data/vehicle/form`,
    component: React.lazy(() =>
      import("views/app-views/master-data/vehicle/form")
    ),
  },

  // Unit/Satuan
  {
    key: "unit-data",
    path: `${APP_PREFIX_PATH}/master-data/unit`,
    component: React.lazy(() =>
      import("views/app-views/master-data/unit/data")
    ),
  },
  {
    key: "unit-form",
    path: `${APP_PREFIX_PATH}/master-data/unit/form`,
    component: React.lazy(() =>
      import("views/app-views/master-data/unit/form")
    ),
  },

  // User Data
  {
    key: "user-data",
    path: `${APP_PREFIX_PATH}/manajemen-pengguna`,
    component: React.lazy(() => import("views/app-views/user-management/data")),
  },
  {
    key: "user-detail",
    path: `${APP_PREFIX_PATH}/manajemen-pengguna/detail`,
    component: React.lazy(() =>
      import("views/app-views/user-management/detail")
    ),
  },
  {
    key: "user-form",
    path: `${APP_PREFIX_PATH}/manajemen-pengguna/form`,
    component: React.lazy(() => import("views/app-views/user-management/form")),
  },
  // Staff
  // {
  //   key: 'staff-data',
  //   path: `${APP_PREFIX_PATH}/manajemen-karyawan`,
  //   component: React.lazy(() =>
  //     import('views/app-views/staff-management/data')
  //   ),
  // },
  // {
  //   key: 'staff-detail',
  //   path: `${APP_PREFIX_PATH}/manajemen-karyawan/detail`,
  //   component: React.lazy(() =>
  //     import('views/app-views/staff-management/detail')
  //   ),
  // },
  // {
  //   key: 'staff-form',
  //   path: `${APP_PREFIX_PATH}/manajemen-karyawan/form`,
  //   component: React.lazy(() =>
  //     import('views/app-views/staff-management/form')
  //   ),
  // },
  // Role & Permission
  {
    key: "role-data",
    path: `${APP_PREFIX_PATH}/role-permission`,
    component: React.lazy(() => import("views/app-views/role-permission/data")),
  },
  {
    key: "role-form",
    path: `${APP_PREFIX_PATH}/role-permission/form`,
    component: React.lazy(() => import("views/app-views/role-permission/form")),
  },

  // KPI
  {
    key: "management-kpi-data-entry",
    path: `${APP_PREFIX_PATH}/management/kpi/data-entry`,
    component: React.lazy(() =>
      import("views/app-views/management/kpi/data-entry")
    ),
  },
  {
    key: "management-kpi-customer-service",
    path: `${APP_PREFIX_PATH}/management/kpi/customer-service`,
    component: React.lazy(() =>
      import("views/app-views/management/kpi/customer-service")
    ),
  },
  {
    key: "management-kpi-procurement",
    path: `${APP_PREFIX_PATH}/management/kpi/procurement`,
    component: React.lazy(() =>
      import("views/app-views/management/kpi/procurement")
    ),
  },
  {
    key: "management-kpi-account-receivable",
    path: `${APP_PREFIX_PATH}/management/kpi/account-receivable`,
    component: React.lazy(() =>
      import("views/app-views/management/kpi/account-receivable")
    ),
  },
  {
    key: "management-kpi-tax",
    path: `${APP_PREFIX_PATH}/management/kpi/tax`,
    component: React.lazy(() => import("views/app-views/management/kpi/tax")),
  },

  // Management
  {
    key: "management-legal",
    path: `${APP_PREFIX_PATH}/management/legal`,
    component: React.lazy(() => import("views/app-views/management/legal")),
  },
  {
    key: "management-legal-form",
    path: `${APP_PREFIX_PATH}/management/legal/form`,
    component: React.lazy(() =>
      import("views/app-views/management/legal/form")
    ),
  },
  {
    key: "management-customer-rating",
    path: `${APP_PREFIX_PATH}/management/customer-rating`,
    component: React.lazy(() =>
      import("views/app-views/management/customer-rating")
    ),
  },
  {
    key: "management-customer-rating-detail",
    path: `${APP_PREFIX_PATH}/management/customer-rating/detail`,
    component: React.lazy(() =>
      import("views/app-views/management/customer-rating/detail")
    ),
  },

  // Profile
  {
    key: "my-profile",
    path: `${APP_PREFIX_PATH}/profile`,
    component: React.lazy(() => import("views/app-views/profile")),
  },
  // {
  //   key: "file-preview",
  //   path: `${APP_PREFIX_PATH}/file-preview`,
  //   component: React.lazy(() => import("views/app-views/file-preview")),
  //   meta: {
  //     blankLayout: true,
  //   },
  // },

  // Other
  {
    key: "error",
    path: `*`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
    meta: {
      blankLayout: true,
    },
  },
];
